// @ts-ignore
import React from 'react'
import $ from 'jquery'
import DataTable from 'datatables.net'
import '../data-table.css'

const OPTIONS = {
  sortable: false,
}

const addStickyForHeader = ($table, numGroups, isVertical = false) => {
  $table.find('thead tr th').addClass('sticky')

  $table.find('thead tr').each((index, tr) => {
    const ths = $(tr).children()
    $(ths).each((i, th) => {
      $(th)
        .css('top', $(tr).position().top - (index + 1) * 1)
        .css('left', i < numGroups && isVertical ? $(th).position().left : 'unset')
        .css('z-index', i < numGroups ? 12 : 10)
    })
  })
}

const addSortData = ($el) => {
  $el.find('td').each(function each(i, th) {
    const tdText = $(th)[0].textContent
    $(th).attr('data-sort', tdText)
  })
}

const addStickyForGrouping = ($el, numGroups) => {
  $el.find('tbody th').each((index, el) => {
    let left = $(el).position().left
    $(el).addClass('sticky').css('left', left)
  })
}

const removeMergeCell = ($el, numGroups) => {
  var trElement: any = '<tr/>'
  $el.find('tbody tr').each(function (index, tr) {
    $(tr)
      .find('th')
      .each(function (i, th) {
        if (this.getAttribute('rowspan')) {
          $(th).attr('rowspan', '')
        }
      })
    const childrenLength = $(tr).find('th').length
    for (let j = numGroups - childrenLength; j > 0; j--) {
      const a = $(trElement).find('th')
      $(tr).prepend($(a[j - 1]).prop('outerHTML'))
    }
    trElement = tr
  })
}

const addDataTable = ($table) => {
  // @ts-ignore
  return new DataTable($table.find('table') as any, {
    paging: false,
    searching: false,
    bInfo: false,
    // scrollY: `${height}px`,
    scrollCollapse: true,
    scrollX: true,
    autoWidth: false,
  })
}

const converImageContent = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('th')
      .each(function each(i, th) {
        const tdText: any = $(th)[0].textContent?.toLocaleLowerCase()
        let isImageTag = tdText.match(
          /^(http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        )
        if (isImageTag) {
          th.className = 'pivot-img-wrapper'
          $(this)
            .html('')
            .append(
              `<div class="pivot-img-wrapper" onclick="$(this).toggleClass('show')">
                <img src=${tdText} 
                  class="pivot-img"
                </img>
                <div class="img-modal">
                  <img src=${tdText} 
                    class="pivot-modal-img"
                  </img>
                </div>
              </div>`
            )
        }
      })
  })
}

const urlFormat = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('th')
      .each(function each(i, th) {
        const tdText: any = $(th)[0].textContent?.toLocaleLowerCase()
        let isImageTag = tdText.includes(`<a href="https://`)
        // console.log('test 1:', $ele)
        // isImageTag && console.log('test 2:', tdText)
        if (isImageTag) {
          th.className = 'pivot-url-wrapper'
          $(this)
            .html('')
            .append(
              `<a class="pivot-url-wrapper" href=${tdText?.split(`"`)[1]} target="_self">
              ${tdText.split('>')[1].split('<')[0]}
              </a>`
            )
        }
      })
  })
}

const columnColor = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('td')
      .each(function each(i, td) {
        const tdText: any = $(td)[0].textContent?.toLocaleLowerCase()
        let isImageTag = tdText.includes(`<a class='timeline-highlight'`)
        // console.log('test 1:', $ele)
        // isImageTag && console.log('test 2:', tdText)
        if (isImageTag) {
          td.className = 'pivot-timeline-highlight-wrapper'
          $(this)
            .html('')
            .append(
              `<div>
              ${tdText.split('>')[1].split('<')[0]}
              </div>`
            )
        }
      })
  })
}

const columnBold = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('td')
      .each(function each(i, td) {
        const tdText: any = $(td)[0].textContent?.toLocaleLowerCase()
        let isImageTag = tdText.includes(`<a class='pivot-table-bold'`)
        // console.log('test 1:', $ele)
        // isImageTag && console.log('test 2:', tdText)
        if (isImageTag) {
          td.className = 'pivot-table-bold-wrapper'
          $(this)
            .html('')
            .append(
              `<div>
              ${tdText.split('>')[1].split('<')[0]}
              </div>`
            )
        }
      })
  })
}
const columnStatusBadge = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('td')
      .each(function each(i, td) {
        const tdText: any = $(td)[0].textContent?.toLocaleLowerCase()
        let isStatusNew = tdText.includes(`<a class='pivot-table-tag-status-new'`)
        let isStatusYellow = tdText.includes(`<a class='pivot-table-tag-status-yellow'`)
        let isStatusGreen = tdText.includes(`<a class='pivot-table-tag-status-green'`)
        let isStatusDeactive = tdText.includes(`<a class='pivot-table-tag-status-deactive'`)
        let isStatusStar = tdText.includes(`<a class='pivot-table-tag-status-star'`)
        let isStatusRed = tdText.includes(`<a class='pivot-table-tag-status-red'`)
        let isStatusClear = tdText.includes(`<a class='pivot-table-tag-status-clear'`)
        let isStatusPromotion = tdText.includes(`<a class='pivot-table-tag-status-promotion'`)
        let isStatusOOS = tdText.includes(`<a class='pivot-table-tag-status-oos'`)
        let isStatusOver = tdText.includes(`<a class='pivot-table-tag-status-over'`)
        let isStatusOK = tdText.includes(`<a class='pivot-table-tag-status-ok'`)
        let isStatusTraffic = tdText.includes(`<a class='pivot-table-tag-status-traffic'`)
        let isStatusProfit = tdText.includes(`<a class='pivot-table-tag-status-profit'`)
        let isStatusImage = tdText.includes(`<a class='pivot-table-tag-status-image'`)
        let isStatusShort = tdText.includes(`<a class='pivot-table-tag-status-short'`)

        // console.log('test 1:', $ele)
        // isImageTag && console.log('test 2:', tdText)
        if (isStatusNew) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              `<div
            className="rounded flex items-center justify-center px-2"
            style={{
              backgroundColor: '#E6F7FF',
              color: '#1890FF',
              width: 'max-content',
              fontWeight: 600,
            }}
          >
            New
          </div>`
            )
        } else if (isStatusYellow) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              `<div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FFFBE6',
                color: '#FAAD14',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Yellow
            </div>`
            )
        } else if (isStatusGreen) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              ` <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#F6FFED',
                color: '#52C41A',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Green
            </div>`
            )
        } else if (isStatusDeactive) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              ` <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FAFAFA',
                color: '#8C8C8C',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Deactive
            </div>`
            )
        } else if (isStatusStar) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              `<div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FFF7E6',
                color: '#FA8C16',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              ⭐ Star
            </div>`
            )
        } else if (isStatusRed) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              `<div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FFF1F0',
                color: '#F5222D',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Red
            </div>`
            )
        } else if (isStatusOver) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              ` <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FFFBE6',
                color: '#FAAD14',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Over
            </div>`
            )
        } else if (isStatusClear) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              ` <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FAFAFA',
                color: '#8C8C8C',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Clear
            </div>`
            )
        } else if (isStatusPromotion) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              `<div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#F6FFED',
                color: '#52C41A',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Promotion
            </div>`
            )
        } else if (isStatusShort) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              ` <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FFF1F0',
                color: '#F5222D',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Short
            </div>`
            )
        } else if (isStatusOOS) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              ` <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FFEC3D',
                color: '#F5222D',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              OOS
            </div>`
            )
        } else if (isStatusOK) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              ` <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FAFAFA',
                color: '#8C8C8C',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              OK
            </div>`
            )
        } else if (isStatusProfit) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              `  <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FAFAFA',
                color: '#8C8C8C',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Profit
            </div>`
            )
        } else if (isStatusTraffic) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              `   <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FAFAFA',
                color: '#8C8C8C',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Traffic
            </div>`
            )
        } else if (isStatusImage) {
          td.className = 'pivot-table-status-wrapper'
          $(this)
            .html('')
            .append(
              ` <div
              className="rounded flex items-center justify-center px-2"
              style={{
                backgroundColor: '#FAFAFA',
                color: '#8C8C8C',
                width: 'max-content',
                fontWeight: 600,
              }}
            >
              Image
            </div>`
            )
        }
      })
  })
}

const columnStylingCell = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('td')
      .each(function each(i, td) {
        const tdText: any = $(td)[0].textContent?.toLocaleLowerCase()
        let firstCase = tdText.includes(`<a class='pivot-table-bold-bgYellow-textRed'`)
        let secondCase = tdText.includes(`<a class='pivot-table-textRed'`)
        let thirdCase = tdText.includes(`<a class='pivot-table-textYellow'`)
        // console.log('test 1:', $ele)
        // isImageTag && console.log('test 2:', tdText)
        if (firstCase) {
          td.className = 'pivot-table-bold-bgYellow-textRed'
          $(this)
            .html('')
            .append(
              `<div>
              ${tdText.split('>')[1].split('<')[0]}
              </div>`
            )
        } else if (secondCase) {
          td.className = 'pivot-table-textRed'
          $(this)
            .html('')
            .append(
              `<div>
              ${tdText.split('>')[1].split('<')[0]}
              </div>`
            )
        } else if (thirdCase) {
          td.className = 'pivot-table-textYellow'
          $(this)
            .html('')
            .append(
              `<div>
              ${tdText.split('>')[1].split('<')[0]}
              </div>`
            )
        }
      })
  })
}

const tableDecorator = (tableRef, numGroups, opts = OPTIONS) => {
  if ($) window['$'] = $
  // Convert string to DOM
  let $container = $(tableRef.current)
  let $table = $($container.find('table'))
  addStickyForHeader($container, numGroups)
  converImageContent($table)
  columnStylingCell($table)
  urlFormat($table)
  columnStatusBadge($table)
  columnColor($table)
  columnBold($table)
  if (opts.sortable) {
    removeMergeCell($table, numGroups)
    const table = addDataTable($table)
  }

  // Convert DOM to string
  // addStickyForGrouping($table, numGroups)
}

export default tableDecorator
