import { isNumber } from 'lodash'
import React from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import kFormatter from 'Utils/kFormatter'
import { dataTest, DATA_TEST_LINE_BAR } from './constant'
import { convertData, convertDataHidden } from './Utils'

const index = ({ data, isNegative = false, hiddenContent = false }) => {
  const { dataSource, lineLabels, barLabels } = convertData(data, isNegative)
  const convertDataHiddenTest = data?.hides ? convertDataHidden(data.labels, data.hides) : {}
  // const CustomizedLabel = (props) => {
  //   console.log('props', props.value)
  //   return (
  //     <g>
  //       <text>{props?.value}</text>
  //     </g>
  //   )
  // }
  const CustomizedLabel = (props) => {
    const { x, y, fill, value, name, width, height, offset } = props
    // console.log('props', props)
    return (
      <text
        name={name}
        x={x + 23}
        y={y + height / 2}
        width={width}
        height={height}
        offset={offset}
        textAnchor="middle"
        className="recharts-text recharts-label"
      >
        <tspan x={x + 23} dy="0.355em">
          {Math.abs(height) >= 13.5 && kFormatter(value)}
        </tspan>
      </text>
    )
  }

  const CustomTooltip = (props) => {
    if (props.active) {
      const { payload, label } = props
      return (
        <div className="relative text-white bg-primary_text min-w-[76px] min-h-[40px] px-2 py-1 text-left text-sm rounded-sm ">
          <div className="font-bold">{props?.label}</div>
          <div>
            {payload?.map((item, index) => (
              <div key={index}>{`${item?.name}: ${kFormatter(item?.value)}`}</div>
            ))}
            {convertDataHiddenTest[label?.toString().replaceAll(' ', '_')]?.map((item, index) => {
              const key = Object?.keys(item)[0]
              const value = Object?.values(item)[0]
              return (
                <div key={index}>{`${key?.replaceAll('_', ' ')}: ${
                  typeof value === 'number' && kFormatter(value)
                }`}</div>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <>
          <div className="hidden"></div>
        </>
      )
    }
  }
  const isProduct360 = window.location.href.split('/')[4] === 'product360'
  return (
    <div>
      {isProduct360 ? (
        <ResponsiveContainer width="100%" minHeight={300}>
          <ComposedChart
            data={dataSource}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid />
            <XAxis
              dataKey="name"
              scale="band"
              interval={0}
              angle={dataSource?.length >= 20 ? -45 : 0}
              textAnchor="end"
            />
            <YAxis tickFormatter={(value) => kFormatter(value)} />
            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={(value) => kFormatter(value)}
              unit="%"
              domain={[0, 100]}
              tickCount={5}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend align="left" />
            {barLabels?.map((item) => (
              <Bar dataKey={item?.title} stackId={'a'} barSize={20} fill={item?.color} />
            ))}
            {/* {lineLabels?.map((item, index) => ( */}
            {lineLabels && (
              <>
                <Line
                  dataKey={lineLabels[0]?.title}
                  yAxisId="right"
                  dot={false}
                  stroke={lineLabels[0]?.color}
                />
                <Line dataKey={lineLabels[1]?.title} dot={false} stroke={lineLabels[1]?.color} />
              </>
            )}
            {/* ))} */}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" minHeight={500}>
          <ComposedChart
            data={dataSource}
            margin={{
              top: 0,
              right: 30,
              left: 20,
              bottom: 30,
            }}
            stackOffset="sign"
          >
            <CartesianGrid />
            <XAxis
              dataKey="name"
              scale="band"
              interval={0}
              angle={dataSource?.length >= 13 ? -45 : 0}
              textAnchor="end"
            />
            <YAxis tickFormatter={(value) => kFormatter(value)} />
            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={(value) => kFormatter(value)}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend align="left" wrapperStyle={{ marginBottom: -30 }} />
            {barLabels?.map((item) => (
              <Bar
                dataKey={item?.title}
                stackId="a"
                barSize={46}
                fill={item?.color}
                // label={CustomizedLabel}
              >
                <LabelList
                  formatter={(value) => kFormatter(value)}
                  dataKey={item?.title}
                  position="middle"
                  content={hiddenContent ? CustomizedLabel : undefined}
                />
              </Bar>
            ))}
            {lineLabels?.map((item) => (
              <Line dataKey={item?.title} yAxisId="right" dot={false} stroke={item?.color} />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default index
