// @ts-ignore
import React from 'react'
import $ from 'jquery'
import DataTable from 'datatables.net'
import '../data-table.css'

const OPTIONS = {
  sortable: false,
}

const addStickyForHeader = ($table, numGroups, isVertical = false) => {
  $table.find('thead tr th').addClass('sticky')

  $table.find('thead tr').each((index, tr) => {
    const ths = $(tr).children()
    $(ths).each((i, th) => {
      $(th)
        .css('top', $(tr).position().top - (index + 1) * 1)
        .css('left', i < numGroups && isVertical ? $(th).position().left : 'unset')
        .css('z-index', i < numGroups ? 12 : 10)
    })
  })
}

const addSortData = ($el) => {
  $el.find('td').each(function each(i, th) {
    const tdText = $(th)[0].textContent
    $(th).attr('data-sort', tdText)
  })
}

const addStickyForGrouping = ($el, numGroups) => {
  $el.find('tbody th').each((index, el) => {
    let left = $(el).position().left
    $(el).addClass('sticky').css('left', left)
  })
}

const removeMergeCell = ($el, numGroups) => {
  var trElement: any = '<tr/>'
  $el.find('tbody tr').each(function (index, tr) {
    $(tr)
      .find('th')
      .each(function (i, th) {
        if (this.getAttribute('rowspan')) {
          $(th).attr('rowspan', '')
        }
      })
    const childrenLength = $(tr).find('th').length
    for (let j = numGroups - childrenLength; j > 0; j--) {
      const a = $(trElement).find('th')
      $(tr).prepend($(a[j - 1]).prop('outerHTML'))
    }
    trElement = tr
  })
}

const addDataTable = ($table) => {
  // @ts-ignore
  return new DataTable($table.find('table') as any, {
    paging: false,
    searching: false,
    bInfo: false,
    // scrollY: `${height}px`,
    scrollCollapse: true,
    scrollX: true,
    autoWidth: false,
  })
}

const converImageContent = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('th')
      .each(function each(i, th) {
        const tdText: any = $(th)[0].textContent?.toLocaleLowerCase()
        let isImageTag = tdText.match(
          /^(http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        )
        if (isImageTag) {
          th.className = 'pivot-img-wrapper'
          $(this)
            .html('')
            .append(
              `<div class="pivot-img-wrapper" onclick="$(this).toggleClass('show')">
                <img src=${tdText} 
                  class="pivot-img"
                </img>
                <div class="img-modal">
                  <img src=${tdText} 
                    class="pivot-modal-img"
                  </img>
                </div>
              </div>`
            )
        }
      })
  })
}

const urlFormat = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('th')
      .each(function each(i, th) {
        const tdText: any = $(th)[0].textContent?.toLocaleLowerCase()
        let isImageTag = tdText.includes(`<a href="https://`)
        // console.log('test 1:', $ele)
        // isImageTag && console.log('test 2:', tdText)
        if (isImageTag) {
          th.className = 'pivot-url-wrapper'
          $(this)
            .html('')
            .append(
              `<a class="pivot-url-wrapper" href=${tdText?.split(`"`)[1]} target="_self">
              ${tdText.split('>')[1].split('<')[0]}
              </a>`
            )
        }
      })
  })
}

const columnColor = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('td')
      .each(function each(i, td) {
        const tdText: any = $(td)[0].textContent?.toLocaleLowerCase()
        let isImageTag = tdText.includes(`<a class='timeline-highlight'`)
        // console.log('test 1:', $ele)
        // isImageTag && console.log('test 2:', tdText)
        if (isImageTag) {
          td.className = 'pivot-timeline-highlight-wrapper'
          $(this)
            .html('')
            .append(
              `<div>
              ${tdText.split('>')[1].split('<')[0]}
              </div>`
            )
        }
      })
  })
}

const columnBold = ($ele) => {
  $ele.find('tbody tr').each(function eachRow(index, tr) {
    $(tr)
      .find('td')
      .each(function each(i, td) {
        const tdText: any = $(td)[0].textContent?.toLocaleLowerCase()
        let isImageTag = tdText.includes(`<a class='pivot-table-bold'`)
        // console.log('test 1:', $ele)
        // isImageTag && console.log('test 2:', tdText)
        if (isImageTag) {
          td.className = 'pivot-table-bold-wrapper'
          $(this)
            .html('')
            .append(
              `<div>
              ${tdText.split('>')[1].split('<')[0]}
              </div>`
            )
        }
      })
  })
}

const tableDecorator = (tableRef, numGroups, opts = OPTIONS) => {
  if ($) window['$'] = $
  // Convert string to DOM
  let $container = $(tableRef.current)
  let $table = $($container.find('table'))
  addStickyForHeader($container, numGroups)
  converImageContent($table)
  urlFormat($table)
  columnColor($table)
  columnBold($table)
  if (opts.sortable) {
    removeMergeCell($table, numGroups)
    const table = addDataTable($table)
  }

  // Convert DOM to string
  // addStickyForGrouping($table, numGroups)
}

export default tableDecorator
