import { Empty } from 'antd'
import LoadingIcon from 'Components/LoadingIcon'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Alert, Skeleton } from 'antd'
import DecorateTable from './tableDecorator'
import {
  getInventoryOverviewPivotTableContent,
  getProduct360PivotTable,
  getProduct360PivotTableContent,
  getProduct360PivotTableTrendviewContent,
  getTrendViewPivotTableContent,
} from 'Services/Analytics/Pivot.service'
import { formatErrors, needStringifyArray } from 'Utils'
import { PivotTableProps } from '../index.constants'
import tableDecorator from './tableDecorator'
import OverlayLoading from 'Components/OverlayLoading'
import { useStoreState } from 'easy-peasy'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import useFilters from 'Pages/Analytics/Components/FiltersHeader/useFilters'
import '../index.scss'

const PivotTable = ({
  currentPage,
  pageSize,
  cate_id,
  analytic_type,
  order_by,
  category_id,
  is360,
  isTrendview,
  is360Overview,
  setTotalPage,
  source,
}: PivotTableProps) => {
  const [loading, setLoading] = React.useState(false)
  const [drawing, setDrawing] = React.useState(false)
  const [error, setError] = React.useState('')
  const [htmlString, setHTMLString] = React.useState('')
  const tableRef = React.useRef<HTMLDivElement>(null)
  const { filters: defaultFilters } = useFilters()

  const history = useHistory()
  const { id } = useParams()
  const queryObject = queryString.parse(history.location.search)

  // SIDE EFFECTS

  // let cancelToken
  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        setError('')
        setLoading(true)
        let html
        html = is360Overview
          ? await getProduct360PivotTable({
              barcode: id,
              from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
              to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
              frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
              // dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
              analytic_type,
              category_id: cate_id,
              // order_by: order_by,

              // cancelToken: cancelTokenSource?.token,
            })
          : is360
          ? isTrendview
            ? await getProduct360PivotTableTrendviewContent({
                barcode: id,
                from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
                to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
                frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
                // dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
                analytic_type,
                // category_id: cate_id,
                order_by: order_by,

                // cancelToken: cancelTokenSource?.token,
              })
            : await getProduct360PivotTableContent({
                barcode: id,
                from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
                to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
                frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
                // dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
                analytic_type,
                // category_id: cate_id,
                // order_by: order_by,

                // cancelToken: cancelTokenSource?.token,
              })
          : analytic_type == 'inventory_overview'
          ? await getInventoryOverviewPivotTableContent({
              per_page: pageSize,
              currentPage: currentPage,
              from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
              to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
              frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
              dimensions: queryObject?.dimensions?.toString() || '[]',
              analytic_type,
              category_id: cate_id,
              order_by: order_by,
              // cancelToken: cancelTokenSource?.token,
            })
          : await getTrendViewPivotTableContent({
              from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
              to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
              frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
              dimensions: queryObject?.dimensions?.toString() || '[]',
              analytic_type,
              category_id: cate_id,
              order_by: order_by,
              source,
              // cancelToken: cancelTokenSource?.token,
            })
        setHTMLString(html?.data?.htmlString || html?.data?.data)
        html?.data?.count && setTotalPage(html?.data?.count)
        setDrawing(true)
        // DECORATE table
        setTimeout(() => {
          tableDecorator(tableRef, { sortable: false })
          setDrawing(false)
        }, 700)
      } catch (error: any) {
        // console.log('error:', error)
        // console.log('error.response:', error?.response?.data)
        if (error.message === 'cancelled') return
        setError(formatErrors(error?.response?.data || 'Something went wrong'))
      }
      setLoading(false)
    }
    fetchData()

    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [
    // category_id,
    currentPage,
    pageSize,
    cate_id,
    analytic_type,
    order_by,
    queryObject?.from_date,
    queryObject?.to_date,
    queryObject?.dimensions,
    source,
  ])

  // RENDER
  if (error) return <Alert message={error} type="error" />

  if (!htmlString && !loading) return <Empty></Empty>

  return (
    <div className="PivotTableComponent w-full">
      {drawing && <OverlayLoading></OverlayLoading>}
      <Skeleton paragraph={{ rows: 15 }} loading={loading} active className="w-full">
        <div ref={tableRef} dangerouslySetInnerHTML={{ __html: htmlString }}></div>
      </Skeleton>
    </div>
  )
}

export default PivotTable
