const COLOR_BAR = ['#1890FF', '#91D5FF', '#D3F261', '#531DAB', '#08979C', '#64748B', '#597EF7']
const COLOR_LINE = [
  '#B37FEB',
  '#5CDBD3',
  '#91D5FF',
  '#FF9C6E',
  '#FFC069',
  '#D3F261',
  '#531DAB',
  '#08979C',
  '#64748B',
  '#597EF7',
]
const COLOR_NEGATIVE_BAR = [
  '#1890FF',
  '#BAE7FF',
  '#5CDBD3',
  '#FF7875',
  '#FFCCC7',
  '#B37FEB',
  '#EFDBFF',
  '#FF9C6E',
  '#FFD8BF',
  '#FF85C0',
  '#E2E8F0',
]
export const convertData = (
  data: any,
  isNegative = false,
  colorBar = COLOR_BAR,
  colorBarNegative = COLOR_NEGATIVE_BAR,
  colorLine = COLOR_LINE
) => {
  var lineLabels: any[] = []
  var barLabels: any[] = []

  data?.bars.map((item: any, index: any) =>
    barLabels.push({
      title: item.title,
      color: !isNegative ? colorBar[index] : colorBarNegative[index],
    })
  )

  data?.lines.map((item: any, index: any) =>
    lineLabels.push({
      title: item.title,
      color: colorLine[index],
    })
  )

  const dataSource: any = data?.labels.reduce((prev: any, curr: any, index: number) => {
    const barDb = data?.bars.reduce((pr: any, cr: any) => {
      return { ...pr, [cr?.title]: cr?.values[index] }
    }, {})
    const lineDb = data?.lines.reduce((pr: any, cr: any) => {
      return { ...pr, [cr?.title]: cr?.values[index] }
    }, {})
    return [...prev, { name: curr, ...barDb, ...lineDb }]
  }, [])
  return { dataSource, lineLabels, barLabels }
}

export const convertDataHidden = (label: any | string[], values: any) => {
  const result = label.reduce((acc: any, curr: any, index) => {
    const valuesConvert = values
      ? values.reduce((acc, curr) => {
          const b = { [curr.title.replaceAll(' ', '_')]: curr.values[index] }
          return [...acc, { ...b }]
        }, [])
      : []
    // const valueBar = bars
    //   ? bars.reduce((acc, curr) => {
    //       const b = { [curr.title.replaceAll(' ', '_')]: curr.values[index] }
    //       return [...acc, { ...b }]
    //     }, [])
    //   : []
    // const valueLine = lines
    //   ? lines.reduce((acc, curr) => {
    //       const b = { [curr.title.replaceAll(' ', '_')]: curr.values[index] }
    //       return [...acc, { ...b }]
    //     }, [])
    //   : []
    return { ...acc, [curr.toString().replaceAll(' ', '_')]: [...valuesConvert] }
  }, {})

  return result
}
const key = {
  Sep_01: {
    Return_revenue: 11,
    New_Return_Customer: 78,
  },
}
