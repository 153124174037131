import React, { useMemo } from 'react'
import { capitalize, uniqBy } from 'lodash'
import { message, Skeleton } from 'antd'

import { CampaignPivotTypes, getBQColumns } from 'Services/Analytics/Pivot.service'
import { capitalizeFirstLetter } from 'Utils'
import PivotElementList from './Components/PivotElementList'
import Table from './Components/Table'
import { PivotTableContainerProps, SelectOptions, DRAG_TYPES } from './index.constants'
import './index.scss'

const ProductV2PivotTable = ({
  is360Overview,
  currentPage,
  pageSize,
  is360,
  order_by,
  className = '',
  cate_id,
  analytic_type,
  isTrendview,
  setTotalPage,
  source,
}: PivotTableContainerProps) => {
  const renderTable = useMemo(() => {
    return (
      <Table
        // store_id={store_id}
        // location={location}
        // isStore={isStore}
        // category_id={category_id}
        isTrendview={isTrendview}
        is360={is360}
        order_by={order_by}
        cate_id={cate_id}
        analytic_type={analytic_type}
        currentPage={currentPage}
        pageSize={pageSize}
        is360Overview={is360Overview}
        setTotalPage={setTotalPage}
        source={source}
      ></Table>
    )
  }, [cate_id, order_by, analytic_type, currentPage, pageSize, source])

  return (
    // <Skeleton paragraph={{ rows: 15 }} loading={true} active>
    <div className={`w-full ProductV2PivotTableContainer ${className}`}>{renderTable}</div>
    // </Skeleton>
  )
}

export default ProductV2PivotTable
